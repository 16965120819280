import React, { useEffect, useState } from "react";
import Header from "../components/Header";
// import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { useSiteData } from "../context/SiteDataProvider";
import Moment from "moment";
import axios from "axios";

import PropTypes from "prop-types";

const LegalPage = ({ page, siteID, APIURL }) => {
  // Page can be one of 'terms-and-conditions', 'privacy-policy'
  LegalPage.propTypes = {
    page: PropTypes.string.isRequired,
    siteID: PropTypes.string.isRequired,
    APIURL: PropTypes.string.isRequired,
  };

  const { siteContent, siteDrawData } = useSiteData();

  const [drawData, setDrawData] = useState([]);
  const [prizeData, setPrizeData] = useState([]);
  const [totalQuantities, setTotalQuantities] = useState(0);

  if (!siteContent || !siteContent.site) {
    return <div>Loading...</div>;
  }
  const {
    site: { siteName, siteURL },
    pages,
  } = siteContent;
  const hostMeta = siteContent.pages.legal["host-meta"];

  const calculateTotalQuantity = (prizeData) => {
    let sum = 0;
    prizeData.forEach((item) => {
      sum += item.prizeQuantity;
    });
    return sum;
  };
  // @TODO: Refactor these into SiteDataProvider
  // useEffect(() => {
  //   axios.get(`${APIURL}/sites/${siteID}/draws`).then(res => {
  //     let allDraw = res.data.content;
  //     let activeDraw = res.data.content.filter(item => item.drawIsClosed !== 1);
  //     let selectedDraw = activeDraw.length > 0 ? activeDraw[0] : allDraw[0];
  //     setDrawData(selectedDraw);
  //   });
  // }, []);

  // useEffect(() => {
  //   if (drawData.drawID) {
  //     axios
  //       .get(`${APIURL}/draws/${drawData.drawID}/prizes`, {
  //         withCredentials: true,
  //         credentials: "same-origin"
  //       })
  //       .then(res => {
  //         setPrizeData(res.data.content);
  //         setTotalQuantities(calculateTotalQuantity(res.data.content));
  //       });
  //   }
  // }, [drawData]);

  return (
    <>
      <Helmet>
        <title>{pages.legal[page].meta.title.replace("{siteName}", siteName)}</title>
        <meta name="description" content={pages.legal[page].meta.description.replace("{siteName}", siteName)} />
      </Helmet>
      <Header />
      <section className="content-section">
        <div className="container">
          <div className="terms-conditions-text">
            <h1 className="l-title mb-2">{pages.legal[page].title}</h1>
            {pages.legal[page].sections.map((section, index) => (
              <React.Fragment key={index}>
                <h2 className="s-title mb-3">{section.title}</h2>
                {section.content.map((paragraph, index) => (
                  <p key={index}>
                    {/* I feel like this should be done on the content initially for easy content. */}
                    {paragraph
                      .replace("{siteName}", siteName)
                      .replace("{siteURL}", siteURL)
                      .replace("{totalQuantities}", totalQuantities)
                      .replace("{drawEndDate}", drawData?.drawEndDate ? Moment(drawData.drawEndDate).format("LLL") : "-----, /--/--/-- 00:00PM")
                      .replace("{causeName}", hostMeta["legal-name"])
                      .replace("{charityNumber}", hostMeta["charity-number"])
                      .replace("{hostEmail}", hostMeta.email)
                      .replace("{hostAddress}", hostMeta.address)
                      .replace("{prizeDetails}", prizeData.map((prize) => `${prize.prizeName} x ${prize.prizeQuantity}`).join("\n"))}
                  </p>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
};

export default LegalPage;
